@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/css/flag-icon.min.css");

h1 {
  @apply text-5xl;
}
h2 {
  @apply text-4xl;
}
h3 {
  @apply text-3xl;
}
h4 {
  @apply text-2xl;
}
h5 {
  @apply text-xl;
}
h6 {
  @apply text-lg;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-sans;
}

code {
  @apply font-mono p-1 inline rounded font-bold;
}
pre {
  @apply font-mono p-2 block rounded font-bold;
  @apply whitespace-pre-wrap;
}

ul {
  @apply list-none;
}

*,
input,
select,
textarea,
option,
button {
  outline: none !important;
  &:focus {
    outline: none !important;
  }
}

/**
 * hide scrollbars
 
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
*/
.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

#__next {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}

body {
  @apply font-sans antialiased text-sm;
}

[data-background="light"] {
  @apply text-gray-900;
  code,
  pre {
    @apply bg-gray-100;
  }
}

[data-background="dark"] {
  @apply text-white;
  code,
  pre {
    @apply bg-gray-800;
  }
}
.link {
  @apply text-blue-500;
}

.ring-red {
  box-shadow: 0 0 0 3px theme("colors.red.300");
}
.ring-green {
  box-shadow: 0 0 0 3px theme("colors.green.300");
}
.ring-white {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
}
.ring-black {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 1);
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-icons {
  vertical-align: middle;
}

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.bg-login {
  background: url(../assets/images/bg-login.png) no-repeat 0 0 fixed;
  background-size: cover;
}

/* social media colors */
.text-facebook {
  color: #365397;
}
.text-twitter {
  color: #00a9f1;
}
.text-github {
  color: #2f2f2f;
}

.text-linkedin {
  color: #006db3;
}
.text-apple {
  color: #737373;
}
.text-google {
  color: #4285f4;
}
.text-google-plus {
  color: #e0452c;
}
.text-youtube {
  color: #ff3333;
}
.text-vimeo {
  color: #48b6ed;
}
.text-pinterest {
  color: #ce1a19;
}
.text-yelp {
  color: #c30f00;
}
.text-dribbble {
  color: #ed4584;
}
.text-amazon {
  color: #ff9700;
}
.text-skype {
  color: #00acf4;
}
.text-instagram {
  color: #396d9a;
}
.text-dropbox {
  color: #0d84de;
}
.text-flickr {
  color: #ea0066;
}
.text-tumblr {
  color: #304c68;
}
.text-foursquare {
  color: #207dc5;
}
